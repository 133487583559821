import React, { useEffect, useRef, useState } from "react";
import styles from "./NewLayout.module.css";
import ess_logo from "./NewLayoutAssets/ess_collapsed.svg";
import Bell from "./NewLayoutAssets/Bell.svg";
import { headerArr } from "./HeaderArray";
import { BiChevronDown } from "react-icons/bi";
import { GoBell } from "react-icons/go";
import { Dropdown, Modal } from "react-bootstrap";
import test from "./NewLayoutAssets/Ellipse 2.png";
import { IoLogOutOutline } from "react-icons/io5";
import { useDispatch } from "react-redux";
import { CgProfile } from "react-icons/cg";
import axios from "axios";
import accounts from "./Notification icon/accounts.svg";
import projects from "./Notification icon/allProjects.svg";
import file from "./Notification icon/fileManager.svg";
import leads from "./Notification icon/manageLeads.svg";
import mom from "./Notification icon/minutesOfMeeting.svg";
import quo from "./Notification icon/quotation.svg";
import report from "./Notification icon/reports.svg";
import task from "./Notification icon/taskManager.svg";
import timeline from "./Notification icon/timeline.svg";
import PO from "./Notification icon/vendorAndPO.svg";
import my_profile from "./Notification icon/my_profile.svg";
import acl from "./Notification icon/acl.svg";
import { useParams } from "react-router-dom";
import profile from "./dropdownImages/profile.svg";
import attendance from "./dropdownImages/attendance.svg";
import userDirect from "./dropdownImages/userDirect.svg";
import logout from "./dropdownImages/logout.svg";
import feeb from "./dropdownImages/feeb.svg";
import itemLib from "./dropdownImages/itemLib.svg";
import teamManage from "./dropdownImages/teamManage.svg";
import libraryTemp from "./dropdownImages/libraryTemp.svg";
import { constants } from "../../../piservices/constants";
import { getReq } from "../../../piservices/apis";
import { getToken } from "../../../piservices/authService";
import Notification from "../../../Notification copy/Notification/Notificatio";
import { logoImg, profileDataData } from "../../../Redux/Slices/persistSlice";
import PlusIconTopBar from "./PlusIconTopBar/PlusIconTopBar"
import { TbSettingsPin } from "react-icons/tb";
import FAQModal from "./FAQModal";

const TopHeaderCommon = () => {
  const [isFAQModalOpen, setIsFAQModalOpen] = useState(false);
  const [arrayDataFAQ, setArrayDataFAQ] = useState([])
  const [headingFAQ, setHeadingFAQ] = useState("")
  const [logoImage, setLogoImage] =useState("");
  const [aclData, setAclData] = useState([]);
  const [profileData, setProfileData] = useState({});
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [unreadCount, setUnreadCount] = useState("");
  const [isNotification, setIsNotification] = useState(false);
  const [notificationModalOpen, setNotificationModalOpen] = useState(false);
  const { id } = useParams();
  const [searchedProjectArray, setsearchedProjectArray] = useState();
  const [searchedProject, setSearchedProject] = useState("");
  const [showNotifications, setShowNotifications] = useState(false);
  const toggleNotifications = () => {
    setShowNotifications(!showNotifications);
  };

  const containerRef = useRef(null);
  const searchProject = async (data) => {
    const res = await axios.post(
      `${constants.PMT_API
      }/api/projects/search-project?userId=${localStorage.getItem(
        "userId"
      )}&status=1&type=1`,
      {
        name: data ? data : "",
      }
    );
    if (res) {
      setsearchedProjectArray(res?.data);
    }
  };
  const handleButtonClickNotification = () => {
    setIsNotification(!isNotification);
  };
  const getProfileData = async () => {
    const res = await getReq(
      `${constants.CRM_API}/user/profile`,
      {},
      { Authorization: `Bearer ${getToken()}` }
    );
    if (res && !res.error) {
      setProfileData({ ...res.data.data });
      dispatch(profileDataData(res.data.data))
      dispatch(logoImg(res.data.data.CompanySettingDetails.companyLogo))
       setLogoImage(res.data.data.CompanySettingDetails.companyLogo );
    } else {
      console.log(res.error);
      // localStorage.clear();
      // window.location.assign(`${constants.BASE_URL}`);
    }
  };
  const getAclData = async () => {
    const res = await getReq(
      `${constants.CRM_API}/user/get-features-list?userId=${localStorage.getItem(
        "userId"
      )}`
    );
    if (res && !res.error) {
      // setProfileData({ ...res.data.data });
      setAclData(res?.data?.data);
      // console.log(res.data)
    } else {
      console.log(res.error);
      // localStorage.clear();
      // window.location.assign(`${BASE_URL}/`);
    }
  };
  let readAll = {
    ids: [],
    userId: "",
  };

  const [loadMoreCount, setLoadMoreCount] = useState(40);
  let idArray = [];
  // const userIdData = localStorage.getItem("userId")
  const [notification, setNotification] = useState([]);
  // const userIdData = localStorage.getItem("userId")
  const [skip, setSkip] = useState(1);
  // const getNotifications = async (skip) => {
  //   const getNotify = await axios.get(
  //     `${constants.NOTIFY_API}/api/notifications/get-notifications?limit=40&skip=${skip}&userId=${localStorage.getItem(
  //       "userId"
  //     )}`
  //   );
  //   setData(getNotify.data);
  //   setNotification((prev) => {
  //     return [...prev, getNotify.data];
  //   });
  // };

  // // console.log({ notification });
  // const [readArr, setReadArr] = useState([]);
  // const readBy = async () => {
  //   readAll.ids = [...readArr];
  //   readAll.userId = localStorage.getItem("userId");
  //   return await axios
  //     .post(
  //       "${constants.NOTIFY_API}/api/notifications/add-readedBy",
  //       readAll
  //     )
  //     .then((res) => {
  //       getNotifications();
  //       setReadArr([]);
  //     })
  //     .catch((err) => console.log(err));
  // };
  // const readByAll = async () => {
  //   return await axios
  //     .post(
  //       `${constants.NOTIFY_API}/api/notifications/read-all-notifications?userId=${localStorage.getItem(
  //         "userId"
  //       )}`
  //     )
  //     .then((res) => getNotifications())
  //     .catch((err) => console.log(err));
  // };
  // const loadMore = () => {
  //   setSkip(skip + 1);
  //   getNotifications(skip + 1);
  //   setLoadMoreCount(loadMoreCount + 40);
  // };

  const unread = async () => {
    const getUnread = await axios.get(
      `${constants.NOTIFY_API}/api/notifications/get-unread-notification-count?userId=${localStorage.getItem(
        "userId"
      )}`
    );
    setUnreadCount(getUnread.data.unreadNotification);
  };
  useEffect(() => {


    unread();

  }, []);
  useEffect(()=>{
    const faviconElement = document.querySelector("link[rel='icon']");
    if(window.location.href.includes("litestore")){
      faviconElement.href = "https://idesign-quotation.s3.amazonaws.com/NO_COMPANYNAME/litestore.ico"; 
    }
    else if (faviconElement) {
     faviconElement.href = logoImage;
   }
   }, [logoImage]);
  const getTime = (date) => {
    if (date === undefined) {
      return "-";
    } else if (typeof date === "string") {
      date = new Date(date);
    }

    let day = date.getDate();
    let month = date.toLocaleDateString("default", { month: "short" });
    let year = date.getFullYear() % 100;
    let ans = "";
    ans += day + "-";
    ans += month + "-";
    ans += year;
    return ans;
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setShowNotifications(false);
        unread()
      }
    };

    // Add the event listener to the document
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // Remove the event listener when the component unmounts
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  function getTimeFormat(date) {
    var date = new Date(date);
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
    // console.log(date?.getDate(), date?.toLocaleString('default', { month: 'short' }), date?.getFullYear())
    // return date?.getTime()
  }

  useEffect(() => {
    getAclData();
  }, []);
  useEffect(() => {
    getProfileData();
  }, []);
  
  const handleDropdownItemClick = () => {
    localStorage.clear();
    window.location.assign(`${constants.BASE_URL}/`);
  };
  // useEffect(() => {
  //   getNotifications();
  // }, []);

  const openFAQModal = () => {
    setIsFAQModalOpen(true);
  };

  const closeFAQModal = () => {
    setIsFAQModalOpen(false);
  };

  return (
    <>
      {/* <Modal
                    title={false}
                    show={notificationModalOpen}
                    onHide={() => {
                        setNotificationModalOpen(false);
                        readBy();
                        setNotification([]);
                    }}
                    size="sm"
                    dialogClassName={styles.notification_modal}
                    closable={false}
                    footer={false}
                >
                    <div className={styles.notifyContainer}>
                        <div className={styles.headingContainer}>
                            <div className={styles.notifyHeading}>Notifications</div>
                            <div className={styles.readUnreadHead} onClick={readByAll}>
                                Mark as read
                            </div>
                        </div>
                        <div className={styles.adminName}>
                            {notification.flat().map((curelem, i) => {
                                {
                                    idArray.push(curelem._id);
                                }

                                let changebackgroundColor = curelem.readedBy.some(
                                    (elem) => {
                                        if (elem === localStorage.getItem("userId")) {
                                            return true;
                                        } else {
                                            return false;
                                        }
                                    }
                                );

                                return (
                                    <>
                                        <div
                                            className={styles.singleAdmin}
                                            style={
                                                changebackgroundColor
                                                    ? { backgroundColor: "#fafafa" }
                                                    : { backgroundColor: "#3b599830" }
                                            }
                                            key={i}
                                            onClick={() => {
                                                setReadArr((prev) => {
                                                    return [...prev, curelem._id];
                                                });

                                                window.open(curelem.featureLink);
                                            }}
                                        >
                                            <div href="#/action-1" className={styles.profile}>
                                                {curelem.featureName === "file manager" ? (
                                                    <img src={file} alt="" height="36" />
                                                ) : curelem.featureName === "Accounts" ? (
                                                    <img src={accounts} alt="" height="36" />
                                                ) : curelem.featureName === "MOM" ? (
                                                    <img src={mom} alt="" height="36" />
                                                ) : curelem.featureName === "projects" ? (
                                                    <img src={projects} alt="" height="36" />
                                                ) : curelem.featureName === "Leads" ? (
                                                    <img src={leads} alt="" height="36" />
                                                ) : curelem.featureName === "Quotation" ? (
                                                    <img src={quo} alt="" height="36" />
                                                ) : curelem.featureName === "projects" ? (
                                                    <img src={report} alt="" height="36" />
                                                ) : curelem.featureName === "projects" ? (
                                                    <img src={PO} alt="" height="36" />
                                                ) : curelem.featureName === "task" ? (
                                                    <img src={task} alt="" height="36" />
                                                ) : curelem.featureName === "projects" ? (
                                                    <img src={timeline} alt="" height="36" />
                                                ) : curelem.featureName === "My profile" ? (
                                                    <img src={my_profile} alt="" height="36" />
                                                ) : curelem.featureName === "ACL" ? (
                                                    <img src={acl} alt="" height="36" />
                                                ) : (
                                                    <CgProfile height="26" />
                                                )}
                                            
                                            </div>

                                            <div className={styles.adminAndStatus} key={i}>
                                            
                                                <div className={styles.taskname}>
                                                    {curelem.notification}
                                                </div>

                                                <div className={styles.status}>
                                                    <span className={styles.projectName}>
                                                        {curelem.projectName}
                                                    </span>
                                                    <span
                                                        style={{
                                                            display: "flex",
                                                            justifyContent: "right",
                                                            fontSize: "0.6rem",
                                                        }}
                                                    >
                                                        {getTime(curelem.createdAt) ===
                                                            getTime(new Date())
                                                            ? getTimeFormat(curelem.createdAt)
                                                            : getTime(curelem.createdAt)}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                );
                            })}
                        </div>
                        {loadMoreCount < unreadCount ? (
                            <div className={styles.seeAll} onClick={loadMore}>
                                Load More
                            </div>
                        ) : (
                            ""
                        )}
                    </div>
                </Modal> */}
      <div className={styles.mainContainer}>
      <img
          src={logoImage}
          className={styles.logo}
          style={{ cursor: "pointer" }}
          onClick={() => {
            window.location.assign(`${constants.BASE_URL}/admin/`);
          }}
          alt=""
        />
        {/* <div
          style={{
            color: "white",
            fontSize: "16px",
            fontWeight: "800",
            height: "100%",
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
          }}
          onClick={() => window.location.assign(`${constants.BASE_URL}/admin/`)}
        >
          UAT
        </div> */}
        <div className="d-flex h-100 w-100 justify-content-between">
          <div
            className="d-flex align-items-center h-100"
            style={{ marginLeft: "4rem" }}
          >
          {headerArr?.map((item, index) => {
            console.log(item,"itemvalue")
              return item?.drop === true
                ? (aclData?.includes(item?.accessName) ||
                    item?.accessName === "default") && (
                    <a
                      className={`${
                        item?.active ? styles.fontActive : styles.font
                      } d-flex align-items-center`}
                      style={{ textDecoration: "none" }}
                      href={item.href}
                      
                      rel="noreferrer"
                    >
                      {item?.label}
                    </a>
                  )
                : (aclData?.includes(item?.accessName) ||
                    item?.accessName === "default") && (
                    <a
                      style={{ textDecoration: "none" }}
                      href={item.href}
                      
                      rel="noreferrer"
                      className={item?.active ? styles.fontActive : styles.font}
                    >
                      {item?.label}
                    </a>
                  );
            })}
            <PlusIconTopBar/>
          </div>
          <div className="d-flex align-items-center">
            <div
              style={{
                position: "relative",
                pointerEvents: showNotifications ? "none" : "auto",
              }}
              onClick={toggleNotifications}
            >
              <span className={styles.unreadCount} style={{ zIndex: "2" }}>
                {unreadCount}
              </span>
              <img
                src={Bell}
                style={{ cursor: "pointer" }}
                //  onClick={() => {
                //     handleButtonClickNotification();
                //     setNotificationModalOpen(true);
                // }}
                onClick={toggleNotifications}
              />
            </div>
            {showNotifications && (
              <div className={styles.notify_pop} ref={containerRef}>
                <Notification unread={unread} setShowNotifications={setShowNotifications} />
              </div>
            )}

            <div className="d-flex align-items-center ms-2">
              <Dropdown>
                <Dropdown.Toggle
                  className="d-flex align-items-center pe-0 w-100"
                  style={{
                    marginRight: "-0.3rem",
                    background: "transparent",
                    border: "none",
                    boxShadow: "none",
                  }}
                >
                  <div
                    className="d-flex align-items-center"
                    style={{
                      borderRadius: "0.5rem",
                      background: "#3D3D3D",
                      padding: "0.25rem 0.5rem",
                    }}
                  >
                    {profileData?.imageUrl &&
                      profileData?.imageUrl?.original &&
                      profileData?.imageUrl?.original != "" ? (
                      <img
                        src={profileData?.imageUrl?.original}
                        style={{
                          height: "24px",
                          width: "24px",
                          borderRadius: "50%",
                        }}
                      />
                    ) : (
                      <div
                        className="d-flex align-items-center justify-content-center"
                        style={{
                          height: "24px",
                          width: "24px",
                          borderRadius: "50%",
                          background: "chocolate",
                        }}
                      >
                        {profileData?.fullName?.charAt(0)?.toUpperCase()}
                      </div>
                    )}
                    <div
                      className="ms-2 d-flex align-items-center"
                      style={{ fontSize: "0.75rem" }}
                    >
                      {profileData?.fullName}
                      <BiChevronDown
                        onClick={() => searchProject()}
                        color="white"
                        size={16}
                      />
                    </div>
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu className="py-2 px-2">
                  <Dropdown.Item
                    style={{}}
                    className={styles.dropdownItem}
                    onClick={() => {
                      window.location.assign(
                        `${constants.BASE_URL}/my-profile/myprofile/personal`
                      );
                    }}
                  >
                    <img src={profile} className="me-1" /> My Profile
                  </Dropdown.Item>

                  {aclData?.includes("accessPanel") && (
                    <Dropdown.Item
                      style={{}}
                      className={styles.dropdownItem}
                      onClick={() => {
                        // dispatch(myprofileState(4));
                        window.location.assign(
                          `${constants.BASE_URL}/my-profile/myprofile/accesspanel`
                        );
                      }}
                    >
                      <img src={teamManage} className="me-1" /> Team Management
                    </Dropdown.Item>
                  )}
                  {/* {aclData?.includes("attendance") && (
                                        <Dropdown.Item
                                            style={{}}
                                            className={styles.dropdownItem}
                                            onClick={() => {
                                                // dispatch(myprofileState(5));
                                                window.location.assign(
                                                    `${constants.BASE_URL}/attendance/todays`
                                                );
                                            }}
                                        >
                                            <img src={attendance} className='me-1' />   Attendance
                                        </Dropdown.Item>
                                    )} */}
                  {/* {aclData?.includes("userDirectory") && (
                    <Dropdown.Item
                      style={{}}
                      className={styles.dropdownItem}
                      onClick={() => {
                        // dispatch(myprofileState(3));
                        window.location.assign(
                          `${constants.BASE_URL}/my-profile/myprofile/userdirectory`
                        );
                      }}
                    >
                      <img src={userDirect} className="me-1" /> User Directory
                    </Dropdown.Item>
                  )} */}
                   {aclData?.includes("companyDetails") && (
                                    <Dropdown.Item
                                        style={{}}
                                        className={styles.dropdownItem}
                                        onClick={() => {
                                            // dispatch(myprofileState(4));
                                            window.location.assign(
                                                `${constants.BASE_URL}/my-profile/myprofile/CompanySetting/CompanyProfile`
                                            );
                                        }}
                                    >
                                        <TbSettingsPin style={{ paddingRight: "4%" }} size={22} />
                                        Company Settings
                                    </Dropdown.Item>
                                )}

                  {/* {aclData?.includes("itemMaster") && (
                                        <Dropdown.Item
                                            style={{}}
                                            className={styles.dropdownItem}
                                            onClick={() => {
                                                // dispatch(myprofileState(5));
                                                window.location.assign(`${constants.BASE_URL}/item-master/`);
                                            }}
                                        >
                                            <img src={itemLib} className='me-1' />  Item Library
                                        </Dropdown.Item>
                                    )} */}

                  {aclData?.includes("libraryTemplate") && (
                    <Dropdown.Item
                      style={{}}
                      className={styles.dropdownItem}
                      onClick={() => {
                        // dispatch(myprofileState(5));
                        window.location.assign(`${constants.BASE_URL}/library/`);
                      }}
                    >
                      <img src={libraryTemp} className="me-1" /> Library &
                      Templates
                    </Dropdown.Item>
                  )}
                  <Dropdown.Item
                    style={{}}
                    className={styles.dropdownItem}
                    onClick={() => {
                      // dispatch(myprofileState(7));
                      window.location.assign(
                        `${constants.BASE_URL}/my-profile/myprofile/feedback`
                      );
                    }}
                  >
                    <img src={feeb} className="me-1" /> Send Feedback
                  </Dropdown.Item>
                  <Dropdown.Item
                    style={{}}
                    className={styles.dropdownItem}
                    onClick={handleDropdownItemClick}
                  >
                    <img src={logout} className="me-1" />
                    Logout
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
      </div>
      {isFAQModalOpen?
      <img  className={styles.faqQuestionImage}
      // style={{position:"fixed", top:"47rem", right:"2rem", height:"2rem", width:"2rem", cursor:"pointer", zIndex:200}}
      src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/crossImg.png" 
      onClick={closeFAQModal}
      />
     :
      <img  className={styles.faqQuestionImage}
      // style={{position:"fixed", top:"47rem", right:"2rem", height:"2rem", width:"2rem", cursor:"pointer", zIndex:200}}
     src="
https://idesign-quotation.s3.amazonaws.com/NO_COMPANYNAME/Frame%201410095953.png" 
     onClick={openFAQModal}
     />
     }
     <FAQModal  show={isFAQModalOpen} onHide={closeFAQModal} />
    </>
  );
};

export default TopHeaderCommon;
